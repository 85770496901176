<template>
  <el-main>
    <div class="row1">
      <div class="row1-box">
        <div class="price">
          <div class="title">店铺余额：</div>
          <div class="row1_price">
            <span>￥</span>
            {{ shopInfo.credit ? shopInfo.credit : 0 }}
          </div>
        </div>
        <el-button size="mini" class="row1_btn" @click="dialogRecharge = true">立即充值</el-button>
      </div>
    </div>
    <!-- 搜索 -->
    <el-form :inline="true" :model="search" class="el-form-search" label-width="120px">
      <el-form-item label="充值/消费类目：">
        <el-select
          v-model="search.rechargeConsumptionType"
          filterable
          size="small"
        >
          <el-option
            v-for="item in typeArr"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="充值/消费：">
        <el-select
          v-model="search.rechargeConsumption"
          filterable
          size="small"
        >
          <el-option
            v-for="item in rechargeConsumptionArr"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="充值/消费时间：">
        <el-date-picker
          v-model="search.rechargeConsumptionTime"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getList">搜索</el-button>
        <el-button size="small">导出</el-button>
        <el-button type="text" size="small" @click="resetSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <RecordTable
      ref="RecordTable"
      style="flex: 1"
      :total="total"
      @getList="getList"
      :list="list"
    ></RecordTable>
    <!-- 充值 -->
    <el-dialog
      :before-close="handleClose"
      title="充值"
      :visible.sync="dialogRecharge"
      :append-to-body="true"
    >
      <div class="dialogBox">
        <el-form style="position: relative" :model="recharge">
          <el-form-item label="店铺名称：" label-width="120px">
            <div>{{ shopInfo.name }}</div>
          </el-form-item>
          <el-form-item label="店铺可用余额：" label-width="120px">
            <div>￥{{ shopInfo.credit ? shopInfo.credit : 0 }}</div>
          </el-form-item>
          <el-form-item label="充值金额：" label-width="120px">
            <el-input
              min="1"
              type="number"
              v-model="rechargeAmount"
              style="width: 100px"
            ></el-input>
            元
            <span v-show="rechargeAmount <= 0" style="margin-left: 20px; color: red"
              >充值金额必须大于0</span
            >
          </el-form-item>
          <el-form-item label="充值后金额：" label-width="120px">
            <div>
              ￥{{
                (Number(shopInfo.credit ? shopInfo.credit : 0) + Number(rechargeAmount)).toFixed(2)
              }}
            </div>
          </el-form-item>
        </el-form>
        <div v-if="status == 1">
          <p>请使用微信扫码支付</p>
          <div style="width: 50%; position: relative">
            <img style="width: 100%" :src="qrcode" alt="" />
            <div class="succ" v-if="succFlag">
              <i class="el-icon-circle-check"></i>
              <p>支付成功</p>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消充值</el-button>
        <el-button type="primary" @click="sureRecharge">确认充值</el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
let timer
import RecordTable from './components/RecordTable'
export default {
  components: {
    RecordTable
  },
  data: function () {
    return {
      total: 0,
      succFlag: false,
      rechargeAmount: 1,
      storeaccount: 233.46,
      search: {
        rechargeConsumptionType: '',
        rechargeConsumption: 0,
        rechargeConsumptionTime: []
      },
      typeArr: [
        {
          value: '0',
          label: '全部'
        },
        {
          value: 'sms',
          label: '短信'
        }
      ],
      rechargeConsumptionArr: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '消费'
        },
        {
          value: 2,
          label: '充值'
        }
      ],
      list: [],
      dialogRecharge: false,
      status: 0,
      qrcode: ''
    }
  },
  computed: {
    // 用户的店铺信息
    shopInfo() {
      return this.$store.getters.shopInfo
    }
  },
  methods: {
    // 弹框关闭时的的钩子
    handleClose() {
      let id = setTimeout(() => {}, 0)
      while (id > 0) {
        window.clearTimeout(id)
        id--
      }
      timer = null
      this.status = 0
      this.succFlag = false
      this.dialogRecharge = false
    },
    // 确认充值
    sureRecharge() {
      let id = setTimeout(() => {}, 0)
      while (id > 0) {
        window.clearTimeout(id)
        id--
      }
      let that = this
      this.$axios
        .post(this.$api.finance.RechargeOrder, {
          price: this.rechargeAmount
        })
        .then((res) => {
          if (res.code == 0) {
            that.status = 1
            that.qrcode = res.result.url
            timer = setInterval(() => {
              that.$axios
                .post(that.$api.finance.getStatus, {
                  ordersn: res.result.ordersn
                })
                .then((res1) => {
                  if (res1.code == 200) {
                    clearInterval(timer)
                    timer = null
                    that.succFlag = true
                    that.dialogRecharge = false
                    that.$store.dispatch('user/getShopInfo')
                  }
                })
            }, 1000)
          } else {
            that.$message.warning(res.msg)
          }
        })
    },
    // 清空搜索条件
    resetSearch() {
      this.search = {
        rechargeConsumptionType: '0',
        rechargeConsumption: 0,
        rechargeConsumptionTime: []
      }
      this.getList()
    },
    // 获取数据
    getList() {
      let that = this
      this.$axios
        .post(this.$api.finance.RechargeGetList, {
          page: this.$refs.RecordTable.page,
          rows: this.$refs.RecordTable.pageNum,
          module:
            this.search.rechargeConsumptionType == '0' ? null : this.search.rechargeConsumptionType,
          type: this.search.rechargeConsumption == 0 ? null : this.search.rechargeConsumption,
          starttime:
            this.search.rechargeConsumptionTime && this.search.rechargeConsumptionTime[0]
              ? this.search.rechargeConsumptionTime[0]
              : null,
          endtime:
            this.search.rechargeConsumptionTime && this.search.rechargeConsumptionTime[1]
              ? this.search.rechargeConsumptionTime[1]
              : null
        })
        .then((res) => {
          if (res.code == 0) {
            that.list = res.result.list
            that.total = res.result.total
          }
        })
    }
  },
  mounted: function () {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
.row1 {
  height: 120px;
  background: url('~@/assets/image/cw_back.png') no-repeat;
  padding-top: 15px;
  margin-bottom: 10px;
  .row1-box {
    height: 105px;
    display: flex;
    justify-content: space-between;
    padding: 0 120px 0 85px;
    width: 100%;
    align-items: center;
    .price {
      display: flex;
      align-items: center;
      color: #fff;
    }
    .title {
      font-size: 20px;
    }
    .row1_price {
      font-size: 24px;
      font-weight: bold;
      span {
        font-size: 20px;
        font-weight: normal;
      }
    }
    .row1_btn {
      border-radius: 20px;
    }
  }
}

/deep/ .el-dialog__header {
  background-color: #f0f0f0;
}
.succ {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  i {
    color: #2fb72f;
    font-weight: bold;
    font-size: 38px;
  }
  p {
    color: #fff;
  }
}
.dialogBox {
  display: flex;
  & > form {
    flex: 1;
    flex-shrink: 0;
  }
  & > div {
    flex: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
