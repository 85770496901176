<template>
  <div class="tableBox">
    <el-table
      fit
      :data="list"
      header-cell-style="background-color:#f8f9fa"
      style="border-left: 1px solid #e8eef1; border-right: 1px solid #e8eef1"
    >
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="充值/消费时间" align="center">
        <template v-slot="{ row }">
          {{ row.create_time | getDate }}
        </template>
      </el-table-column>
      <el-table-column label="充值/消费类目" align="center">
        <template v-slot="{ row }">
          {{ row.module == 'sms' ? '短信' : '' }}
        </template>
      </el-table-column>
      <el-table-column label="充值/消费金额" align="center">
        <template v-slot="{ row }">
          {{ row.type == 1 ? '-' : '+' }}
          {{ row.price }}
        </template>
      </el-table-column>
      <el-table-column label="充值/消费" align="center">
        <template v-slot="{ row }">
          {{ row.type == 1 ? '消费' : '充值' }}
        </template>
      </el-table-column>
      <el-table-column prop="from_text" label="详情" align="center"> </el-table-column>
    </el-table>

    <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
  </div>
</template>

<script>
import Paging from '@/components/paging'
import { getDateformat } from '@/util/getDate'
export default {
  filters: {
    getDate(index) {
      return getDateformat(index)
    }
  },
  components: {
    Paging
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: () => 0
    }
  },
  data: function () {
    return {
      page: 1,
      pageNum: 10
    }
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val
      } else {
        this.page = val
      }
      // 重新获取数据
      this.$emit('getList')
    }
  }
}
</script>

<style lang="less" scoped>
.tableBox {
  display: flex;
  flex-direction: column;
  .el-table {
    flex: 1;
    /deep/ .el-table__body-wrapper {
      height: calc(100% - 48px);
    }
  }
}
</style>
